// extracted by mini-css-extract-plugin
export var matchId = "_matchId-module--matchId--AKmFL";
export var matchId__youtube_wrapper = "_matchId-module--matchId__youtube_wrapper--8Ppil";
export var matchId__list = "_matchId-module--matchId__list--yQoPq";
export var matchId__list_card = "_matchId-module--matchId__list_card--aMRcl";
export var matchId__boxwrap = "_matchId-module--matchId__boxwrap---+bsB";
export var matchId__set = "_matchId-module--matchId__set--5hAT8";
export var matchId__newscategory = "_matchId-module--matchId__newscategory--8VJd0";
export var matchId__newstitle = "_matchId-module--matchId__newstitle--Nm6b3";
export var result__box = "_matchId-module--result__box--nk6Dv";
export var result__winner = "_matchId-module--result__winner--g1Ov7";
export var result__match_title = "_matchId-module--result__match_title--llpCd";
export var result__number_wrap = "_matchId-module--result__number_wrap---mcQA";
export var result__number = "_matchId-module--result__number--fgrE0";
export var result__vs = "_matchId-module--result__vs--mgRMH";
export var result__thema = "_matchId-module--result__thema--rUnsr";
export var result__blue = "_matchId-module--result__blue--KWbZL";
export var result__red = "_matchId-module--result__red--zltL0";
export var result__artist = "_matchId-module--result__artist--WN33e";