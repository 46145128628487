import React from 'react';
import { graphql } from 'gatsby';
import { dayjs } from '../../lib/dayjs';
import { Layout } from '../../components/layout';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_matchId.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import Accent from '/static/accent.svg';
import classNames from 'classnames';
import { isNewsTypeColor } from '../../lib/is-news-type-color';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../../utils/contentPathHelper';
import defaultUserIcon from '../../images/icon_user_default.jpg'

const ResultPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const relatedArticles = () => {
    if (!data.microcmsMatch.event.news_relation.id) {
      return [];
    }

    return data.allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        if (relation.id === data.microcmsMatch.event.news_relation.id) {
          check = true;
        }
      });

      return check;
    });
  };

  const postDescription = data.microcmsMatch.blue && data.microcmsMatch.red && `${t('match.blueCorner')} ${data.microcmsMatch.blue.name ?? ''} VS ${t('match.redCorner')} ${data.microcmsMatch.red.name ?? ''}`;

  return (
    <Layout location={location}>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.match'),
            link: '/match',
          },
        ]}
        currentPage={data.microcmsMatch.title}
      />
      <Seo title={`${data.microcmsMatch.title}${t('match.seo.title')}`} description={postDescription} />
      <BackGround>
        <section className={classNames(Styles.matchId, CommonStyles.wrapper)}>
          <div className={CommonStyles.container}>
            <h1>{data.microcmsMatch.title}</h1>
            {data.microcmsMatch.video_id && (
              <div className={Styles.matchId__youtube_wrapper}>
                <iframe width="560" height="315" src={`https://www.youtube.com/embed/${data.microcmsMatch.video_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            )}
            {/* ---start 対戦結果一覧と同じスタイルになると思います。 --- */}
            <div className={Styles.result__list}>
              {/* <p className={Styles.result__date}>{dayjs(data.microcmsMatch.date).format('MM月DD日(ddd)')}</p> */}
              <div className={Styles.result__box}>
                {/*青アーティストアイコン*/}
                <div className={classNames(data.microcmsMatch.winner[0] === '青' && Styles.result__winner)}>
                  <div className={Styles.result__artist}>
                    {data.microcmsMatch.blue.length > 0 && data.microcmsMatch.blue.map((b, i) => (
                      <Link to={`/artist/${contentPathHelper(b.id)}/`}>
                        <img key={i} src={b.image_url || b.image.url + '?w=200&q=80&fm=webp'} alt={`${b.name}${t('match.icon')}`} />
                        <p>{b.name}</p>
                      </Link>
                    ))}
                    {data.microcmsMatch.blue.length === 0 && (
                      <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                    )}
                  </div>
                </div>

                <div>
                  <p className={Styles.result__match_title}>{data.microcmsMatch.title}</p>
                  <div className={Styles.result__number_wrap}>
                    <p className={Styles.result__number}>
                      <strong>{data.microcmsMatch.blue_point}</strong>
                      {data.microcmsMatch.blue_examination !== null && <small>({data.microcmsMatch.blue_examination})</small>}
                    </p>
                    <p className={Styles.result__vs}>VS</p>
                    <p className={Styles.result__number}>
                      <strong>{data.microcmsMatch.red_point}</strong>
                      {data.microcmsMatch.red_examination !== null && <small>({data.microcmsMatch.red_examination})</small>}
                    </p>
                  </div>
                  <p className={Styles.result__thema}>
                    <span className={Styles.result__blue}>{data.microcmsMatch.concrete}</span>
                    <span>+</span>
                    <span className={Styles.result__red}>{data.microcmsMatch.abstract}</span>
                  </p>
                </div>

                {/*赤アーティストアイコン*/}
                <div className={classNames(data.microcmsMatch.winner[0] === '赤' && Styles.result__winner)}>
                  <div className={Styles.result__artist}>
                    {data.microcmsMatch.red.length > 0 && data.microcmsMatch.red.map((r, i) => (
                      <Link to={`/artist/${contentPathHelper(r.id)}/`}>
                        <img key={i} src={r.image_url || r.image.url + '?w=200&q=80&fm=webp'} alt={`${r.name}${t('match.icon')}`} />
                        <p>{r.name}</p>
                      </Link>
                    ))}
                    {data.microcmsMatch.red.length === 0 && (
                      <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                    )}
                  </div>
                </div>

              </div>
            </div>
            {/* ---end 対戦結果一覧と同じスタイルになると思います。 -- */}
            <h2>
              <img src={Accent} className="accent" alt="accent" />
              {t('match.relatedArticles')}
            </h2>
            {data.microcmsMatch.event.news_relation.id ? (
              <ul className={Styles.matchId__list}>
                {relatedArticles()
                  .slice(0, 3)
                  .map(({ node }) => (
                    <li key={node.newsId} className={Styles.matchId__list_card}>
                      <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                        <img src={node.image.url} alt="" />
                        <div className={Styles.matchId__boxwrap}>
                          <div className={Styles.matchId__set}>
                            {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                            <p className={Styles.matchId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                              {node.type[0]}
                            </p>
                          </div>
                          <p className={Styles.matchId__newstitle}>{node.title}</p>
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>{t('menu.noArticles')}</p>
            )}
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($matchId: String) {
    microcmsMatch(matchId: { eq: $matchId }) {
      matchId
      event {
        news_relation {
          id
        }
      }
      title
      date
      red {
        id
        name
        image_url
        image {
          url
        }
      }
      red_point
      red_examination
      blue {
        id
        name
        image_url
        image {
          url
        }
      }
      blue_point
      blue_examination
      concrete
      abstract
      video_id
      publishedAt
      winner
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
          }
        }
      }
    }
  }
`;

export default ResultPage;
