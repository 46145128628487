export const isNewsTypeColor = (string) => {
  switch (string) {
    case 'ニュース':
      return '#ffb26b';
    case 'イベント':
      return '#addd60';
    case 'ひとリミッツ':
      return '#abcdef';
    default:
      return '#ffffff';
  }
};
